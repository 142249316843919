import React, { useEffect, Text, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { ToastContainer } from "react-toastify";
import { toastControl } from "../../lib/toasControl";
import Pagination from "react-js-pagination";
import myBase from "../../base";

// ACTIONS
import { loadNewsCategories } from "../../redux/actions/newsCategoryActions";
import * as actions from "../../redux/actions/newsActions";

//STYLES
import css from "./__.module.css";

// -- HTML
import Section from "../../Components/General/Section";
import PageTitle from "../../Components/PageTitle";
import Dropdown from "../../Components/General/Dropdown";
import CardBoby from "../../Components/General/CardBody";
import Spinner from "../../Components/General/Spinner";
import Spinner2 from "../../Components/General/Spinner2";
import Model from "../../Components/General/Model";

//-- filter Image
import notfound from "../../notfound.svg";

const News = (props) => {
  // -- USESTATE
  //-- PAGINATION
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState({});
  const [total, setTotal] = useState();

  // SEARCH STATE
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState({});
  const [select, setSelect] = useState(
    "status name slug categories pictures type views vip vip_images createAt"
  );

  // DELETE CHECKBOX
  const [chkBox, setChkBox] = useState([]);
  const [deleteModel, setDeleteModel] = useState(false);

  // USEEFFECT
  useEffect(() => {
    init();
  }, []);

  // Ямар нэгэн алдаа эсвэл амжилттай үйлдэл хийгдвэл энд useEffect барьж аваад TOAST харуулна
  useEffect(() => {
    toastControl("error", props.error);
  }, [props.error]);

  useEffect(() => {
    if (props.success) {
      toastControl("success", props.success);
      init();
    }
  }, [props.success]);

  useEffect(() => {
    if (props.pageLast) {
      setTotal(props.pageLast.total);
      setLimit(props.pageLast.limit);
    }
  }, [props.pageLast]);

  useEffect(() => {
    let searchData = "";
    Object.keys(search).map((key) => {
      searchData += `&${key}=${search[key]}`;
    });

    props.loadNews(
      `select=${select}&sort=${sort}&page=${activePage}${searchData}`
    );

    props.loadPagination(props.pageLast);
  }, [activePage, search]);

  //-- FUNCTIONS
  // INIT
  const init = () => {
    props.clear();
    setCategory(() => null);
    setChkBox(() => []);
    props.loadCategories();
    props.loadNews(`select=${select}`);
  };

  const addClick = () => {
    props.history.push("/news/add");
  };

  const handleShowModel = () => {
    deleteModel === true ? setDeleteModel(false) : setDeleteModel(true);
  };

  const handleClose = () => {
    setDeleteModel(false);
  };

  // FILTER HANDLE
  const handleChange = (e) => {
    setSearch((bs) => ({ ...bs, [e.target.name]: e.target.value }));
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const deleteClick = () => {
    let ids = [];
    chkBox.map((el) => {
      ids.push(el.id);
    });

    props.deleteMultNews(ids);
    setDeleteModel(false);
  };

  const handleChk = (e) => {
    let ch = chkBox;
    let checks = [];
    if (e.target.checked === false) {
      ch.map((el, index) => {
        if (el.id === e.target.value) {
          ch.splice(index, 1);
        }
      });
    } else {
      checks[e.target.value] = { check: e.target.checked, id: e.target.value };
      ch.push(checks[e.target.value]);
    }
    setChkBox((b) => [...b]);
  };

  return (
    <Section>
      <MetaTags>
        <title> Нийтлэл | WEBR Control Panel</title>
        <meta name="description" content="Нийтлэл | WeBR control panel" />
        <meta property="og:title" content="Нийтлэл | web control panel" />
      </MetaTags>
      <PageTitle name={`Нийтлэл`} />

      <div className="row">
        <div className={css.PanelControl}>
          <div className="col-md-4">
            <div className={css.PanelTabelHeader}>
              <button
                name="addBtn"
                onClick={addClick}
                className="myButton addBtn"
              >
                <i className="fas fa-plus-circle"></i> Нийтлэл оруулах{" "}
              </button>
              <button
                name="refresh"
                onClick={() => init()}
                className="myButton refreshBtn"
              >
                <i className="fas fa-redo-alt"></i> Сэргээх
              </button>
              {chkBox.length > 0 && (
                <button
                  name="news"
                  onClick={handleShowModel}
                  className="myButton refreshBtn deleteBtn"
                >
                  <i className="fas fa-trash-alt"></i> Устгах
                </button>
              )}
            </div>
          </div>
          <div className="col-md-8"></div>
        </div>
        <div className="col-md-12">
          {props.loading ? <Spinner /> : null}
          <CardBoby>
            <div className={`card-body`}>
              <div className="card-header">
                <h3 className="card-title" style={{ fontSize: 14 }}>
                  Сонгогдсон : {chkBox.length}
                </h3>
                <div className={`card-tools ${css.Pagination}`}>
                  {!total ? (
                    <Spinner2 />
                  ) : (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={limit}
                      totalItemsCount={total}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange.bind()}
                    />
                  )}
                </div>
              </div>

              <table className={`myTable table`}>
                <thead>
                  <tr>
                    <th></th>
                    <th className="statusTh">Төлөв </th>
                    <th> Төрөл </th>
                    <th> Зураг </th>
                    <th>Гарчиг</th>
                    <th>Ангилал</th>
                    <th> VIP76 </th>
                    <th> Үзсэн </th>
                    <th>Нэмсэн огноо</th>
                    <th>Үйлдэл</th>
                  </tr>
                </thead>
                <tr>
                  <td> # </td>
                  <td>
                    <select
                      name="status"
                      onChange={handleChange}
                      className="my-input searchInput"
                    >
                      <option value=""> Төлөв сонгоно уу </option>
                      <option value={true}> Идэвхтэй </option>
                      <option value={false}> Идэвхгүй </option>
                    </select>
                  </td>
                  <td>
                    <select
                      name="type"
                      onChange={handleChange}
                      className="my-input searchInput"
                    >
                      <option value=""> Төрөл сонгоно уу </option>
                      <option value="video"> Видео </option>
                      <option value="picture"> Зураг </option>
                      <option value="audio"> Аудио </option>
                      <option value="default"> Энгийн </option>
                    </select>
                  </td>
                  <td> </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      className="my-input searchInput"
                      placeholder="Гарчигаас хайлт хийх..."
                    />{" "}
                  </td>
                  <td>
                    <select
                      name="category"
                      onChange={handleChange}
                      className="my-input searchInput"
                    >
                      <option value=""> Мэдээний ангилалаас сонгоно уу </option>
                      {props.categories &&
                        props.categories.map((category) => (
                          <option value={category._id}>
                            {" "}
                            {category.name}{" "}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name="vip"
                      onChange={handleChange}
                      className="my-input searchInput"
                    >
                      <option value=""> VIP76 - аас татсан мэдээ </option>
                      <option value={1}> Vip76 - аас татсан </option>
                      <option value={0}> Vip76 - аас татаагүй </option>
                    </select>
                  </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                </tr>
                {props.news &&
                  props.news.map((el) => (
                    <tr key={el._id}>
                      <td className="checkTd">
                        <input
                          type="checkbox"
                          value={el._id}
                          className="chk"
                          onChange={handleChk}
                        />
                      </td>
                      <td className="statusTd">
                        {el.status == true ? (
                          <div className="activeOn"></div>
                        ) : (
                          <div className="activeOff"></div>
                        )}
                      </td>
                      <td className={css.Type}>
                        <span>
                          {(el.type === "default" && "Энгийн") ||
                            (el.type === "video" && "Видео") ||
                            (el.type === "audio" && "Аудио") ||
                            (el.type === "picture" && "Фото")}
                        </span>
                      </td>
                      <td>
                        {el.pictures && el.pictures.length > 0 && (
                          <div className="tableImgBox">
                            <img
                              src={`${myBase.cdnUrl}uploads/150x150/${el.pictures[0]}`}
                              className="tableImg"
                            />
                          </div>
                        )}
                        {el.vip === 1 &&
                          el.vip_images &&
                          el.vip_images.length > 0 && (
                            <div className="tableImgBox">
                              <img
                                src={`${el.vip_images[0]}`}
                                className="tableImg"
                              />
                            </div>
                          )}
                      </td>
                      <td>
                        {el.name}
                        ...
                      </td>
                      <td className="categoryList">
                        {el.categories.map((el) => (
                          <Link to={`/news-category`}>{el.name}</Link>
                        ))}
                      </td>
                      <td>{el.vip === 1 ? "vip76.mn мэдээ" : "Бусад"}</td>
                      <td> {el.views}</td>
                      <td>{el.createAt}</td>
                      <td>
                        <div className={css.AllActions}>
                          <a
                            className={`${css.Actions} ${css.View}`}
                            href={`${myBase.siteUrl}post/${el.slug}`}
                            target="_blank"
                          >
                            <i className="fas fa-info"></i>
                          </a>
                          <Link
                            className={`${css.Actions} ${css.Edit}`}
                            to={`/news/edit/${el._id}`}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
              {props.loading === false && props.news && props.news.length < 1 && (
                <div className={css.Notfound}>
                  <p> "Илэрц олдсонгүй" </p>
                  <img src={notfound} />
                </div>
              )}
              <div className={css.DashboardFooter}>
                <p>
                  Нийт дата: <strong> {total} </strong>
                </p>
              </div>
            </div>
          </CardBoby>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Model
        modelName="Сошиал хаяг устгах"
        show={deleteModel}
        handleToggle={handleClose}
      >
        <div>
          <p>
            Сонгогдсон нийт: <strong> {chkBox.length} </strong> нийтлэлийг
            устгахдаа итгэлтэй байна уу ?
          </p>
        </div>
        <div className={css.BtnGroup}>
          <button className="btn btn-success btn-sm" onClick={deleteClick}>
            Устгах
          </button>
          <button className="btn btn-light btn-sm" onClick={handleClose}>
            Болих
          </button>
        </div>
      </Model>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.newsCategoryReducer.newsCategories,
    news: state.newsReducer.allNews,
    pageLast: state.newsReducer.paginationLast,
    loading: state.newsReducer.loading,
    success: state.newsReducer.success,
    error: state.newsReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(actions.clear()),
    loadCategories: () => dispatch(loadNewsCategories()),
    loadNews: (query) => dispatch(actions.loadNews(query)),
    loadPagination: (pageLast) => dispatch(actions.loadPagination(pageLast)),
    deleteMultNews: (ids) => dispatch(actions.deleteMultNews(ids)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
