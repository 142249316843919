import react, { useEffect, Component } from "react";
import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../../pageStyle.css";
import "react-multiple-select-dropdown-lite/dist/index.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useCookies, CookiesProvider } from "react-cookie";
import { connect } from "react-redux";

// Import components
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Side from "../../Components/Side";

// Import page
import Dashboard from "../Dashboard";
import News from "../News";
import User from "../Users";
import Contact from "../Contact";
import Banner from "../Banner";
import { default as BannerAdd } from "../Banner/Add";
import { default as BannerEdit } from "../Banner/Edit";
import { default as NewsAdd } from "../News/Add";
import { default as NewsView } from "../News/View";
import { default as NewsEdit } from "../News/Edit";
import NewsCategory from "../NewsCategory";
import Question from "../Question";
import { default as LoginPage } from "../Login";
import Notfound from "../Notfound";
import Logout from "../Logout";
import { default as UserAdd } from "../Users/Add";
import { default as UserEdit } from "../Users/Edit";
import { default as UserView } from "../Users/View";
import Page from "../Page";
import { default as pageAdd } from "../Page/Add";
import { default as pageEdit } from "../Page/Edit";
import Partners from "../Partners";
import { default as partnersAdd } from "../Partners/add";
import { default as partnersEdit } from "../Partners/edit";
import UserProfile from "../UserProfile";
import EditUser from "../UserProfile/edit";
import FooterMenu from "../FooterMenu";
import { default as Forget } from "../Forget";

import Menu from "../Menu";
// Actions
import { tokenCheck } from "../../redux/actions/tokenActions";
import WebInfo from "../WebInfo";

function App(props) {
  const [cookies, setCookie, removeCookie] = useCookies(["autobiztoken"]);

  useEffect(() => {
    if (cookies.autobiztoken) {
      const token = cookies.autobiztoken;
      props.checkToken(token);
    }
  }, []);

  useEffect(() => {
    if (props.tokenError) {
      removeCookie("autobiztoken");
      document.location.href = "/login";
    }
  }, [props.tokenError]);

  return (
    <>
      {cookies.autobiztoken ? (
        <>
          <CookiesProvider>
            <Header />
            <Side />
            <Switch>
              <Route path="/news" component={News} exact />
              <Route path="/news/add" component={NewsAdd} exact />
              <Route path="/news/view/:id" component={NewsView} />
              <Route path="/news/edit/:id" component={NewsEdit} />
              <Route path="/news-category" component={NewsCategory} />

              <Route path="/webinfo" component={WebInfo} />
              <Route path="/users/add" component={UserAdd} />
              <Route path="/users/edit/:id" component={UserEdit} />
              <Route path="/users/view/:id" component={UserView} />
              <Route path="/users" component={User} />

              <Route path="/banners/add" component={BannerAdd} />
              <Route path="/banners/edit/:id" component={BannerEdit} />
              <Route path="/banners" component={Banner} />

              <Route path="/question" component={Question} />

              <Route path="/page/add" component={pageAdd} />
              <Route path="/page/edit/:id" component={pageEdit} />
              <Route path="/page" component={Page} />

              <Route path="/partners/edit/:id" component={partnersEdit} />
              <Route path="/partners/add" component={partnersAdd} />
              <Route path="/partners" component={Partners} />

              <Route path="/menu" component={Menu} />
              <Route path="/contact" component={Contact} />
              <Route path="/userprofile" component={UserProfile} />
              <Route path="/settings" component={EditUser} />

              <Route path="/" exact component={Dashboard} />
              <Route path="/logout" component={Logout} />
              <Route path="/footer-menu" component={FooterMenu} />
              <Redirect to="/" />
              <Route path="*" component={Notfound} />
            </Switch>
            <Footer />
          </CookiesProvider>
        </>
      ) : (
        <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route path="/login" component={LoginPage} />
          <Route parh="/forget-password" exact component={Forget} />
          <Redirect to="/login" />
        </Switch>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tokenError: state.tokenReducer.error,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    checkToken: (token) => dispatch(tokenCheck(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(App);
