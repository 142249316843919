const myBase = {
  cdnUrl: "https://darkhan-avarga-baterdene.mn/",
  baseUrl: "https://admin.darkhan-avarga-baterdene.mn/",
  siteUrl: "https://darkhan-avarga-baterdene.mn/",
  // cdnUrl: "http://localhost:8060/",
  // baseUrl: "https://localhost:3001/",
  // siteUrl: "https://localhost:3000/",
};

export default myBase;
